<template>
  <div class="payzilla">
    <!-- 顶部title -->
    <div class="top-img">
      <div class="top-center">
        <div class="top-title">
          <div>Our mission is to bridge the fiat and crypto world</div>
        </div>
        <div class="top-text">
          We provide an on/off-ramping platform to help customers engage in Web3
          and the metaverse, while offering businesses efficient tools to start,
          operate, and scale, reducing payment and spending costs.
        </div>
        <!-- <div class="top-img">
          <img src="../../assets/image/logo6.png" alt="" />
        </div> -->
      </div>
    </div>

    <!-- 轮播 -->
    <div class="swiper-bag">
      <div class="swiper-top">
        <div class="swiper-top-card">
          <div class="card-title">Our Mission</div>
          <div class="card-text">
            Be the leading infrastructure for digital assets enabling all to
            have access to digital growth opportunities.
          </div>
        </div>
        <div class="swiper-top-card">
          <div class="card-title">Our Vision</div>
          <div class="card-text">
            To enable and accelerate access to digital assets for individuals
            and businesses in Asia and beyond.
          </div>
        </div>
      </div>
      <div class="swiper-content">
        <div class="content-title">Our Values</div>
        <div class="content-center">
          <div class="content-box">
            <div class="content-box-left">
              <div
                :class="ourValuesIndex == index ? 'border-left' : ''"
                v-for="(item, index) in ourValuesList"
                :key="index"
                @click="handleOurValues(index)"
              >
                {{ item }}
              </div>
            </div>
            <div
              :class="ourValuesIndex == index ? '' : 'display-i-b'"
              class="content-box-right"
              v-for="(item, index) in ourValuesTextList"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-title">Our Key Partners</div>
      <div class="swiperBox">
        <div class="swiperBox-left" @click="currentIndexPrev()">
          <img src="../../assets/image/icon14.png" alt="" />
        </div>
        <el-carousel
          v-if="width == 1"
          class="swiperBox-box"
          ref="swiperRef"
          :autoplay="true"
          arrow="never"
          indicator-position="none"
          :loop="true"
        >
          <el-carousel-item v-if="width == 1">
            <div class="swiperBox-card">
              <div class="swiperBox-logo">
                <img src="../../assets/image/logo3.png" alt="" />
              </div>
              <div class="swiperBox-logo swiperBox-logo4">
                <img src="../../assets/image/logo4.png" alt="" />
              </div>
              <div class="swiperBox-logo">
                <img class="" src="../../assets/image/logo5.png" alt="" />
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item v-if="width == 1">
            <div class="swiperBox-card">
              <div class="swiperBox-logo">
                <img src="../../assets/image/logo3.png" alt="" />
              </div>
              <div class="swiperBox-logo swiperBox-logo4">
                <img src="../../assets/image/logo4.png" alt="" />
              </div>
              <div class="swiperBox-logo">
                <img class="" src="../../assets/image/logo5.png" alt="" />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <el-carousel
          v-if="width == 2"
          class="swiperBox-H5"
          ref="swiperRef"
          :autoplay="true"
          arrow="never"
          indicator-position="none"
          :loop="true"
        >
          <el-carousel-item class="carousel-item">
            <div class="swiperBox-logo">
              <img src="../../assets/image/logo3.png" alt="" />
            </div>
          </el-carousel-item>
          <el-carousel-item class="carousel-item">
            <div class="swiperBox-logo swiperBox-logo4">
              <img src="../../assets/image/logo4.png" alt="" />
            </div>
          </el-carousel-item>
          <el-carousel-item class="carousel-item">
            <div class="swiperBox-logo">
              <img src="../../assets/image/logo5.png" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="swiperBox-right" @click="currentIndexNext">
          <img src="../../assets/image/icon15.png" alt="" />
        </div>
      </div>
    </div>
    <el-dialog
      class="dialog-web"
      title="Tips"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <span class="dialog-text"
        >drop us an enquiry email to: support@payzilla.io</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button
          class="dialog-btn"
          type="primary"
          @click="dialogVisible = false"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <!-- 弹框 -->
    <div class="bottom-dialog" v-if="dialogVisibleH5">
      <div class="dialog-top">
        <div>Tips</div>
        <div @click="dialogVisibleH5 = false">X</div>
      </div>
      <div class="dialog-center">
        drop us an enquiry email to: support@payzilla.io
      </div>
      <div class="dialog-bottom-btn">
        <div @click="dialogVisibleH5 = false" class="btn">Confirm</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about",
  data() {
    return {
      width: 2, // 1 web 2 H5
      currentIndex: 0,
      supLogo1: false,
      supLogo2: false,
      supLogo3: false,
      supLogo4: false,
      dialogVisible: false,
      dialogVisibleH5: false,
      ourValuesIndex: 0,
      ourValuesList: [
        "Passion for innovation",
        "Multi culture",
        "Huiman centric",
      ],
      ourValuesTextList: [
        "Passion for innovation drives us forward, pushing us to explore, challenge, and turn ideas into impactful results that advance technology and deliver value to our clients and industry.",
        "Embracing diverse cultures and respecting global users and partners to drive industry growth in an open, innovative environment.",
        "Placing people at the core of everything we do, driving the balance between business and social responsibility by focusing on individuals, and strengthening long-term relationships with stakeholders.",
      ],
      ourValuesText:
        "Passion for innovation drives us forward, pushing us to explore, challenge, and turn ideas into impactful results that advance technology and deliver value to our clients and industry.",
    };
  },
  created() {
    // supRefs
    // console.log(this.$refs.supRefs.getBoundingClientRect().top)
  },
  mounted() {
    //获取页面宽度
    let userAgent = navigator.userAgent.toLowerCase();
    if (
      userAgent.indexOf("mobile") !== -1 ||
      userAgent.indexOf("android") !== -1 ||
      userAgent.indexOf("iphone") !== -1 ||
      userAgent.indexOf("ipad") !== -1 ||
      userAgent.indexOf("windows phone") !== -1
    ) {
      this.width = 2;
    }
    this.checkMediaQuery();
    window.addEventListener("resize", this.checkMediaQuery);
  },
  methods: {
    //监听页面宽度变化
    checkMediaQuery() {
      if(/Android|iPhone|iPad|iPod/i.test(navigator.platform)){
        this.width = 2;
      }else{
        const mediaQueryList = window.matchMedia("(max-width: 768px)");
        if (mediaQueryList.matches) {
          if (this.width == 1) this.width = 2;
        } else {
          if (this.width == 2) this.width = 1;
        }
      }
    },
    handleOurValues(index) {
      this.ourValuesIndex = index;
      const ourValuesTextList = this.ourValuesTextList;
      this.ourValuesText = ourValuesTextList[index];
    },
    currentIndexPrev() {
      this.$refs.swiperRef.prev();
    },
    currentIndexNext() {
      this.$refs.swiperRef.next();
    },
    handleRamps() {
      // window.location.href = "https://example.com";
    },
    handlePDF(url) {
      if (url) window.open(url);
    },
    handleContact() {
      if (this.width == 1) this.dialogVisible = true;
      else this.dialogVisibleH5 = true;
    },
  },
};
</script>

<style lang="scss" scoped>
//.animateFadeOut{
//  animation: fadeOut;
//}
//@keyframes fadeOut {
//  from {
//    opacity: 1;
//  }
//
//  to {
//    opacity: 0;
//  }
//}
.swiper-wrapper {
  transition-timing-function: linear !important; //想好具体位置放到哪，得能替换！
}

@import "../../css/public.scss";

.color-051513 {
  color: #051513;
}

.color-00A965 {
  color: #00a965;
}

.payzilla {
  background-image: url("../../assets/image/image7.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -1000px;
  font-family: "font1", sans-serif;
  -webkit-text-size-adjust: 100% !important; /* 或者使用 'none' */
}

.top-img {
  background-image: url("../../assets/image/image7.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0px 0px;
  padding-bottom: 550px;

  .top-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;

    .top-title {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: #051513;
      padding-top: 200px;
      font-size: 70px;
      color: #fff;
      // font-weight: 700;;
      font-family: "font2", sans-serif !important;

      div {
        width: 920px;
      }
    }

    .top-text {
      width: 550px;
      font-size: 22px;
      color: #fff;
      margin-top: 30px;
      // font-weight: 400;;
      line-height: 32px;
    }

    .top-img {
      width: 150px;
      height: 69px;
      padding: 4px 15px;
      cursor: pointer;
      margin-top: 20px;
    }
  }
}

.swiper-bag {
  padding: 50px 15%;

  .swiper-top {
    display: flex;
    justify-content: center;
    margin-top: 150px;

    & > :nth-child(1) {
      margin-right: 25px;
      box-shadow: 1px 1px 8px #00a96533 !important;
    }

    .swiper-top-card {
      box-shadow: 2px 2px 8px #00a96533;
      border-radius: 5px;
      padding: 40px 28px;
      background-color: #fff;
      text-align: center;
      width: 620px;

      .card-title {
        font-size: 32px;
        font-family: "font2", sans-serif !important;
        // font-weight: 700;;
        margin-bottom: 15px;
      }

      .card-text {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  .swiper-content {
    .content-title {
      text-align: center;
      font-size: 44px;
      // font-weight: 700;;
      margin-top: 250px;
      font-family: "font2", sans-serif !important;
    }

    .content-center {
      .content-box {
        padding: 50px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content-box-left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-right: 100px;
          width: 640px;

          .border-left {
            border-left: 4px solid #00a965;
            color: #00a965;
          }

          div {
            border-left: 4px solid #d3d7de;
            padding: 12px 16px;
            font-size: 28px;
            color: #1b273699;
            font-family: "font2", sans-serif !important;
            // font-weight: 700;;
            cursor: pointer;
          }
        }

        .display-i-b {
          display: none !important;
        }

        .content-box-right {
          flex: 1;
          animation: rotateTr 0.9s ease;
          color: #1b2736b3;
          font-size: 26px;
          line-height: 36px;
          // font-weight: 400;;
        }
      }
    }
  }

  .swiper-title {
    text-align: center;
    font-size: 44px;
    // font-weight: 700;;
    margin-top: 250px;
    font-family: "font2", sans-serif !important;
  }

  .swiperBox {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    .swiperBox-left {
      margin-right: 50px;
      height: 130px;
      display: flex;
      align-items: center;

      img {
        width: 45px;
        height: 45px;
      }
    }

    .swiperBox-right {
      height: 130px;
      display: flex;
      align-items: center;
      margin-left: 50px;

      img {
        width: 45px;
        height: 45px;
      }
    }

    .swiperBox-box {
      width: 1150px;

      .swiperBox-card {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .swiperBox-logo {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 350px;
          height: 130px;
          background-image: url("../../assets/image/image5.png");
          background-position: 100%;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          box-shadow: 0 4px 8px #1b27361a;

          img {
            width: 200px;
            height: 50px;
          }
        }

        .swiperBox-logo4 {
          img {
            width: 250px;
            height: 38px;
          }
        }
      }
    }
  }
}

.bottom {
  background-color: #051513;
  margin-top: 100px;
  color: #fff;
  padding: 100px 0;

  .bottom-btm {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .btm-top {
      width: 1280px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      // font-weight: 500;

      .btm-top-left {
        width: 165px;
        height: 32px;
      }

      .right-content {
        font-size: 18px;
        cursor: pointer;

        .content-flex div:hover {
          color: #00d37e;
        }

        .right-content-icon {
          display: flex;

          img {
            width: 18px;
            height: 18px;
            margin-right: 15px;
          }
        }

        div {
          margin-bottom: 20px;
          // font-weight: 700;;
          font-family: "font2", sans-serif !important;
        }

        & > :nth-child(1) {
          color: #adadad !important;
          font-size: 12px !important;
          margin-bottom: 25px !important;
        }
      }
    }
  }

  .fw-400 {
    width: 100%;
    margin: auto;
    font-size: 18px;
    line-height: 28px;
    text-align: center;

    span {
      display: inline-block;
      width: 600px;
    }
  }

  .btm-font-text {
    width: 920px;
    margin: auto;
    font-size: 16px;
    line-height: 18px;
    opacity: 0.7;
    text-align: center;
    font-family: "font1-Light";
  }

  .mt-200 {
    margin-top: 80px;
  }

  .mt-25 {
    margin-top: 25px;
  }
}
@keyframes rotateTr {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@media screen and (max-device-width: 768px) {
  .top-img {
    padding-top: 300px;
    background-size: 115% 60%;
    background-position: 0px 1400px;
    padding-bottom: 900px;

    .top-center {
      width: 100%;
      padding-top: 180px;
      background-color: #051513;

      .top-title {
        width: 78%;
        text-align: center;
        font-size: 140px;
        color: #fff;
        line-height: 180px;

        div {
          width: 100%;
        }
      }

      .top-text {
        background-color: #051513;
        width: 1300px;
        font-size: 74px;
        line-height: 120px;
        text-align: center;
        color: #fff;
        margin-top: 100px;
      }

      .top-img {
        width: 700px;
        height: 300px;
        margin-top: 150px;
      }
    }
  }

  .payzilla {
    background-image: none;
    -webkit-text-size-adjust: 100% !important; /* 或者使用 'none' */
  }

  .swiper-bag {
    padding: 0;

    .swiper-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 300px;
      width: 100%;

      & > :nth-child(1) {
        margin-right: 0;
        margin-bottom: 120px;
        box-shadow: 1px 1px 40px #00a96533 !important;
      }

      .swiper-top-card {
        box-shadow: 1px 1px 40px #00a96533;
        border-radius: 25px;
        padding: 140px 100px;
        background-color: #fff;
        text-align: center;
        width: 85%;
        box-sizing: border-box;

        .card-title {
          font-size: 130px;
          font-family: "font2", sans-serif !important;
          // font-weight: 700;;
          margin-bottom: 40px;
        }

        .card-text {
          font-size: 75px;
          // font-weight: 400;;
          line-height: 110px;
        }
      }
    }

    .swiper-content {
      .content-title {
        font-size: 165px;
        // font-weight: 700;;
        margin-bottom: 150px;
        margin-top: 300px;
        padding-top: 300px;
      }

      .content-center {
        .content-box {
          padding: 50px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .content-box-left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-right: 0px;
            width: 85%;

            .border-left {
              border-left: none;
              border-bottom: 15px solid #00a965;
              color: #00a965;
            }

            div {
              width: 100%;
              border-left: none;
              border-bottom: 15px solid #d3d7de;
              padding: 60px 0px;
              font-size: 110px;
              color: #1b273699;
              font-family: "font2", sans-serif !important;
              // font-weight: 700;;
              cursor: pointer;
            }
          }

          .content-box-right {
            margin-top: 200px;
            width: 85%;
            color: #1b2736b3;
            font-size: 85px;
            line-height: 120px;
          }
        }
      }
    }

    .swiper-title {
      text-align: center;
      font-size: 165px;
      // font-weight: 700;;
      margin-bottom: 250px;
      margin-top: 300px;
      padding-top: 270px;
    }

    .swiperBox {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
   margin-bottom: 450px;
      .swiperBox-left {
        margin-right: 60px;
        height: 260px;
        display: flex;
        align-items: center;

        img {
          width: 185px;
          height: 185px;
        }
      }

      .swiperBox-right {
        height: 400px;
        display: flex;
        align-items: center;
        margin-left: 60px;

        img {
          width: 185px;
          height: 185px;
        }
      }

      .swiperBox-H5 {
        width: 1200px;
        height: 800px;
        box-shadow: 0 8px 16px #1b27361a;
        background-image: url("../../assets/image/image5.png");
        background-position: 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 50px;

        .carousel-item {
          display: flex;
          justify-content: center;
          align-items: center;

          box-shadow: 0 8px 16px #1b27361a;

          .swiperBox-logo {
            img {
              width: 750px;
              height: 190px;
            }
          }

          .swiperBox-logo4 {
            img {
              width: 850px !important;
              height: 140px !important;
            }
          }
        }
      }
    }
  }

  ::v-deep .el-carousel__container {
    width: 100%;
    height: 100%;
  }

  
  .bottom-dialog {
    width: 75vw;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 100px 80px;
    color: #051513;

    .dialog-top {
      display: flex;
      justify-content: space-between;
      font-size: 70px;
      // font-weight: 400;;
      font-family: "font1-Light" !important;
      opacity: 0.7;
    }

    .dialog-center {
      font-size: 70px;
      // font-weight: 400;;
      margin: 150px 0;
      font-family: "font1-Light" !important;
      opacity: 0.7;
    }

    .dialog-bottom-btn {
      display: flex;
      flex-direction: row-reverse;

      div {
        width: 400px;
        font-size: 85px;
        // font-weight: 700;;
        text-align: center;
        border-radius: 15px;
        color: #fff;
        padding: 30px 50px;
        background-color: #00a965;
      }
    }
  }
}

::v-deep .dialog-web .el-dialog__title {
  color: #051513;
  // font-weight: 400;;
  font-size: 18px;
  line-height: 18px;
}

::v-deep .dialog-web .el-dialog__headerbtn .el-dialog__close {
  color: #051513;
  /* 修改关闭图标的颜色 */
}

.dialog-btn {
  width: 116px;
  height: 48px;
  border-radius: 5px;
  padding: 0;
  background-color: #00a965;
  border-color: #00a965;
  font-size: 18px;
  // font-weight: 700;;
  font-family: "font2";
}

.dialog-text {
  // font-weight: 400;;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  font-family: "font1-Light";
  color: #051513;
}
</style>